<template>
  <div>
    <CCard>
      <CCardHeader>
        <h3>
          <CIcon name="cil-layers" size="xl" />
          <span
            v-for="(category, index) in selectedCategoriesTree"
            :key="index"
          >
            <span
              :class="categoryIdToEdit ? 'category-header-disabled' : 'category-header'"
              @click="navigateToCategory(category)"
              >{{ category.name }}</span
            >
            {{ index == selectedCategoriesTree.length - 1 ? "" : " / " }}
          </span>
          {{
            categoryIdToEdit == "00000000-0000-0000-0000-000000000000"
              ? "/ Create Category"
              : categoryIdToEdit
              ? "/ Edit Category"
              : ""
          }}
        </h3>
      </CCardHeader>
      <CCardBody v-if="categoryIdToEdit">
        <create-or-update-category
          :id="categoryIdToEdit"
          :parentId="
            selectedCategory?.id ??
            selectedCategoriesTree[selectedCategoriesTree.length - 1].id
          "
          @onOperationFinish="afterCreateOrUpdate"
        />
      </CCardBody>
    </CCard>
    <div v-show="!categoryIdToEdit" class="categories-grid">
      <CCard>
        <CCardHeader>
          <h3>
            {{ selectedCategoriesTree[selectedCategoriesTree.length - 2]?.name ?? selectedCategoriesTree[selectedCategoriesTree.length - 1]?.name }}
          </h3>
        </CCardHeader>
        <CCardBody>
          <CListGroup>
            <CListGroupItem
              v-for="(category, i) in parentCategories"
              :key="i"
              component="a"
              href="#"
              :color="light"
              :disabled="categoryIdToEdit != null"
              :active="selectedCategory.id == category.id"
              @click="selectMainCategory(category)"
              >{{ category.name
              }}
              <CButton style="float: right" :disabled="categoryIdToEdit" @click="deleteCategory(category, true)">
                <CIcon name="cil-pencil" /> Delete
              </CButton>
              <CButton style="float: right" :disabled="categoryIdToEdit" @click="edit(category.id, category, false)">
                <CIcon name="cil-pencil" /> Edit
              </CButton>
            </CListGroupItem>
          </CListGroup>
        </CCardBody>
      </CCard>
      <CCard>
        <CCardHeader>
          <CRow
            style="
              justify-content: space-between;
              margin-right: 0;
              margin-left: 0;
            "
          >
            <h3>Sub Categories</h3>
            <CButton style="float: right" @click="create()" :disabled="categoryIdToEdit">
              <CIcon name="cil-plus" /> Add New Category
            </CButton>
          </CRow>
        </CCardHeader>
        <CCardBody>
          <CDataTable
            v-if="selectedCategory != null"
            :items="subCategories"
            :fields="fields"
            :loading="isSubCategoriesGridLoading"
            hover
            pagination
          >
            <template #name="{ item }">
              <td :class="categoryIdToEdit ? 'category-header-disabled' : 'category-header'" @click="getCategoryData(item.id, item, false, true)">
                <strong>{{ item.name }}</strong>
              </td>
            </template>

            <template #description="{ item }">
              <td>
                <strong>{{ item.description }}</strong>
              </td>
            </template>

            <template #subCategories="{ item }">
              <td>
                <strong>{{ item.subCategories }} subcategories</strong>
              </td>
            </template>

            <template #experiences="{ item }">
              <td @click="navigateToExperiences(item)">
                <strong>{{ item.experiences }} experiences</strong>
              </td>
            </template>

            <template #sortOrder="{ item }">
              <td>
                <strong>{{ item.sortOrder }}</strong>
              </td>
            </template>

            <template #EDIT="{ item }">
              <td style="width: 10%">
                <CButton @click="edit(item.id, item, true)" :disabled="categoryIdToEdit">
                  <CIcon name="cil-pencil" /> Edit
                </CButton>
              </td>
            </template>
            <template #DELETE="{ item }">
              <td style="width: 10%">
                <CButton @click="deleteCategory(item, false)" :disabled="categoryIdToEdit">
                  <CIcon name="cil-pencil" /> Delete
                </CButton>
              </td>
            </template>
          </CDataTable>
          <span v-else> Please select a category </span>
        </CCardBody>
      </CCard>
    </div>
    <CModal
    v-if="categoryToDelete"
          v-model="categoryToDelete.id"
          :title="'Are you sure to delete ' + categoryToDelete.names[0].name + ' category?'"
          color="danger"
          :show.sync="warningModal"
          @update:show="closeModal"
        >
        </CModal>
  </div>
</template>

<script>
import axios from "axios";
import CreateOrUpdateCategory from "@/views/categories/Create";
import { nextTick } from 'vue';

export default {
  name: "Categories",
  components: {
    CreateOrUpdateCategory,
  },
  data: () => {
    return {
      emptyGuid: "00000000-0000-0000-0000-000000000000",

      // Delete
      category: [],
      parentId: " ",
      warningModal: false,
      categoryToDelete: null,
      //List
      categories: [],
      parentCategories: [],
      subCategories: [],
      parents: [],
      subs: [],
      selectedCategoriesTree: [],
      fields: [
        "name",
        "description",
        "subCategories",
        "experiences",
        "sortOrder",
        "EDIT",
        "DELETE"
      ],
      selectedCategory: null,
      categoryIdToEdit: null,
      isMainCategory: false,
      level: 0,
      isSubCategoriesGridLoading: false,

      //Alert
      message: null,
      alertType: "danger",
    };
  },
  computed: {},
  methods: {
    closeModal(status, evt, accept) {
      if (accept) {
        this.delete(this.categoryToDelete.id);
      }
    },
    deleteCategory(category, isMain){
      this.categoryToDelete = category;
      this.isMainCategory = isMain;
      this.warningModal = true;
    },
    delete(id) {
      let self = this;
      axios
        .delete(`${this.$apiAdress}/v1/category/${id}`)
        .then(function () {
                    self.alertType = "success";
          setTimeout(function () {
              self.message = null;
          }, 5000);
          self.message = "Successfully deleted category.";
          self.deleted = true;
          if(self.isMainCategory)
            self.afterCreateOrUpdate(true);
          else
            self.afterCreateOrUpdate();
        })
        .catch(function (error) {
          self.deleted = true;
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    getCategoryData(parent, category, ignoreLevelIncrement = false, changeMainCategory = false, categoriesToSubstract = 1) {
      if(this.categoryIdToEdit){
        return;
      }
      let self = this;
      if(parent && changeMainCategory && this.selectedCategoriesTree.length - categoriesToSubstract >= 0){
        parent = this.selectedCategoriesTree[this.selectedCategoriesTree.length - categoriesToSubstract].id;
      }
      axios
        .get(`${this.$apiAdress}/v1/category/${parent}/data`)
        .then(function (response) {
          self.parentCategories = [];
          self.subCategories = [];
          self.parents = response.data.parentCategories;
          self.subs = response.data.subCategories;
          self.parents.map(function (value, key) {
            value.name = value.names[0].name;
            value.description = value.descriptions[0].name;
            value.subCategories = value.subCategoriesCount;
            value.experiences = value.experiencesCount;
            self.parentCategories.push(value);
          });
          self.subs.map(function (value, key) {
            value.name = value.names[0].name;
            value.description = value.descriptions[0].name;
            value.subCategories = value.subCategoriesCount;
            value.experiences = value.experiencesCount;
            self.subCategories.push(value);
          });
          if (parent && category) {
            if (!ignoreLevelIncrement) {
              self.level++;
            }
            self.subCategories = [];
            if(category.level){
              self.selectMainCategoryFromHeader(category);
            }
            else{
              self.addSelectedCategoryToTree(category);
              self.selectMainCategory(category);
            }
            
          }
          if (self.level == 0) {
            self.subCategories = [...self.parentCategories];
            self.selectedCategory = {};
          } else if(!changeMainCategory) {
            self.selectedCategory = null;
          }
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    navigateToExperiences(item) {
      if (item.experiencesCount > 0) {
        const link = `experiences?categoryId=${item.id.toString()}`;
        this.$router.push({ path: link });
      }
    },
    create() {
      this.categoryIdToEdit = "00000000-0000-0000-0000-000000000000";
    },

    edit(id, category, isSubCategory = false) {
      if(isSubCategory){
        this.selectedCategoriesTree.push({
          name: category.names[0].name,
          id: category.id,
          level: this.level,
          parentId: category.parentId,
          isSubCategory: true
        });
      }
      else{
        this.selectedCategory = category;
        if(this.selectedCategoriesTree.length == 1){
          this.selectedCategoriesTree.push({
          name: category.names[0].name,
          id: category.id,
          level: this.level,
          parentId: category.parentId,
          isSubCategory: true
        });
        }
        else if(this.selectedCategoriesTree[this.selectedCategoriesTree.length - 1].id != category.id){
          this.selectedCategoriesTree[this.selectedCategoriesTree.length - 1].name = category.names[0].name;
          this.selectedCategoriesTree[this.selectedCategoriesTree.length - 1].id = category.id;
        }
      }
      this.$nextTick(() => {
        this.categoryIdToEdit = id;
        });
      
    },

    getSubCategories(id, parent) {
      let self = this;
      self.isSubCategoriesGridLoading = true;
      axios
        .get(`${this.$apiAdress}/v1/category/${id}/parent`)
        .then(function (response) {
          self.subCategories = [];
          self.subs = response.data;
          self.subs.map(function (value, key) {
            value.name = value.names[0].name;
            value.description = value.descriptions[0].name;
            value.subCategories = value.subCategoriesCount;
            value.experiences = value.experiencesCount;
            self.subCategories.push(value);
          });
          self.isSubCategoriesGridLoading = false;
        })
        .catch(function (error) {
          self.isSubCategoriesGridLoading = false;
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    selectMainCategory(category, loadSubCategories = true) {
      if(loadSubCategories)
        this.getSubCategories(category.id);
      this.selectedCategory = category;
      if (
        this.selectedCategoriesTree[this.selectedCategoriesTree.length - 1]
          .parentId != category.parentId ||
        this.level == 0
      )
        this.level++;
      this.addSelectedCategoryToTree(category);
    },
    afterCreateOrUpdate(deleted) {
      this.categoryIdToEdit = null;
      var subCategoryIndex = this.selectedCategoriesTree.findIndex(selectedCategory => selectedCategory.isSubCategory);
      if(deleted){
        this.selectedCategoriesTree.splice(this.selectedCategoriesTree.length - 1, 1);
      this.navigateToCategory(
        this.selectedCategoriesTree[this.selectedCategoriesTree.length - 1]
      );
      }
      else{
        if(subCategoryIndex != -1)
        this.selectedCategoriesTree.splice(subCategoryIndex, 1);
      this.navigateToCategory(
        this.selectedCategoriesTree[this.selectedCategoriesTree.length - 1]
      );
      }

    },
    addSelectedCategoryToTree(category) {
      var currentCategoryLevelIndex = this.selectedCategoriesTree.findIndex(
        (selectedCategory) => selectedCategory.level == this.level
      );
      var currentCategoryIndex = this.selectedCategoriesTree.findIndex(
        (selectedCategory) => selectedCategory.id == category.id
      );
      if (currentCategoryLevelIndex == -1 && currentCategoryIndex == -1) {
        this.selectedCategoriesTree.push({
          name: category.names[0].name,
          id: category.id,
          level: this.level,
          parentId: category.parentId,
        });
      } else {
        this.selectedCategoriesTree[currentCategoryLevelIndex] = {
          name: category.names[0].name,
          id: category.id,
          level: this.level,
          parentId: category.parentId,
        };
      }
    },
    navigateToCategory(category) {
      if(this.categoryIdToEdit){
        return;
      }
      var selectedCategoryIndex = this.selectedCategoriesTree.findIndex(
        (selectedCategory) => selectedCategory.id == category.id
      );
      this.selectedCategoriesTree.splice(
        selectedCategoryIndex + 1,
        this.selectedCategoriesTree.length - selectedCategoryIndex
      );
      this.level =
        this.selectedCategoriesTree[
          this.selectedCategoriesTree.length - 2
        ]?.level;
      if(category.level == 0){
        this.level = 0;
        this.getCategoryData(this.parentId, null, true);
      }
      else{
        this.getCategoryData(category.id, category, false, true, 2);
      }
      
    },
    selectMainCategoryFromHeader(category){
      var parentCategory = this.parentCategories.find(subCategory => subCategory.id == category.id);
      this.selectMainCategory(parentCategory);
      
    }
  },

  mounted: function () {
    this.getCategoryData(this.parentId, null, true);
    this.selectedCategoriesTree.push({
      name: "Main Category",
      id: " ",
      level: 0,
      parentId: null,
    });
  },
};
</script>

<style scoped>
.card-body >>> table > tbody > tr > td {
  cursor: pointer;
}

.categories-grid {
  display: grid;
  grid-template-columns: 0.25fr 0.75fr;
  grid-gap: 15px;
}

.list-group-item.active {
  color: #000;
  background-color: #eff1f1;
  border-color: #eff1f1;
}

.category-header:hover {
  cursor: pointer;
}

.category-header-disabled:hover{
  cursor: not-allowed;
}
</style>
